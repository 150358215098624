import React from 'react';

export default function Home() {
  return (
    <div>
      <h1>Welcome</h1>
      <p>
        To get started, either choose <em>create poll</em> from the
        navigation menu or follow a <em>view poll</em> link from a friend.
      </p>
    </div>
  );
}
