import './ViewPoll.css';
import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PublicPoll from '../models/PublicPoll';

export default function ViewPoll() {
  const { pollId } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const mapRef = useRef<L.Map>(null!);
  const markerRef = useRef<L.Marker | null>(null);
  const [poll, setPoll] = useState<PublicPoll>(null!);

  useEffect(() => {
    fetch(`poll/${pollId}`)
      .then(response => response.json())
      .then(
        result => {
          setPoll(result);
          setIsLoaded(true);
        },
        error => alert(error),
      );
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    const map = L.map('map')
      .setView({
        lat: (poll.topLeft.lat + poll.bottomRight.lat) / 2,
        lng: (poll.topLeft.lng + poll.bottomRight.lng) / 2,
      })
      .setMaxBounds(L.latLngBounds([poll.topLeft, poll.bottomRight]));

    map.setZoom(map.getBoundsZoom(L.latLngBounds([poll.topLeft, poll.bottomRight])));

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    L.rectangle(L.latLngBounds([poll.topLeft, poll.bottomRight]))
      .addTo(map);

    poll.votes.forEach(vote => {
      const marker = L.marker(vote.latLng).addTo(map);

      if (vote.isYou) {
        marker.bindPopup('This is where you voted.');
        marker.openPopup();
        markerRef.current = marker;
      }
    });

    map.on('click', event => {
      if (markerRef.current !== null) {
        markerRef.current.bindPopup('To change your vote, press Reset.');
        markerRef.current.openPopup();
      } else if (
        event.latlng.lat <= poll.topLeft.lat
        && event.latlng.lat >= poll.bottomRight.lat
        && event.latlng.lng <= poll.bottomRight.lng
        && event.latlng.lng >= poll.topLeft.lng
      ) {
        const marker = L.marker(event.latlng).addTo(map);
        marker.bindPopup('You are now ready to submit.');
        marker.openPopup();
        markerRef.current = marker;
      } else {
        L.popup()
          .setLatLng(event.latlng)
          .setContent('Your vote must be within the poll bounds.')
          .openOn(map);
      }
    });

    mapRef.current = map;
  }, [isLoaded]);

  function reset() {
    if (markerRef.current !== null) {
      markerRef.current.remove();
      markerRef.current = null;
    }
  }

  function submit() {
    if (markerRef.current === null) {
      return;
    }

    fetch(`poll/${pollId}/vote`, {
      body: JSON.stringify(markerRef.current.getLatLng()),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then(response => {
        if (!response.ok) {
          response.text().then(text => alert(text));
        } else {
          response.text().then(text => window.location.replace(text));
        }
      });
  }

  if (!isLoaded) {
    return <p>Loading...</p>;
  } else {
    return (
      <div>
        <p>
          Click where you wish to vote, then press Submit.
          To redo a vote, press Reset, then repeat the voting process.
        </p>
        <p>Votes on this poll so far: {poll.votes.length}</p>
        <div id="map"></div>
        <div id="buttons">
          <button id="reset" className="btn btn-secondary" onClick={reset}>Reset</button>
          <button id="submit" className="btn btn-primary" onClick={submit}>Submit</button>
        </div>
      </div>
    );
  }
}
