import './CreatePoll.css';
import React, { Component } from 'react';
import L from 'leaflet';

interface CreatePollState {
  map: L.Map,
  points: L.LatLng[],
}

export default class CreatePoll extends Component<{}, CreatePollState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      map: null!,
      points: [],
    };
    this.reset = this.reset.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    const map = L.map('map').setView([0, 0], 1);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    map.on('click', event => {
      let content;

      if (this.state.points.length === 0) {
        content = 'Click the other corner of the rectangle.';
        this.state.points.push(event.latlng);
        this.renderMarkers();
      } else if (this.state.points.length === 1) {
        content = 'You are now ready to submit.';
        this.state.points.push(event.latlng);
        this.renderMarkers();
      } else {
        content = 'To modify the bounds, click the Reset button.';
      }

      L.popup()
        .setLatLng(event.latlng)
        .setContent(content)
        .openOn(map);
    });

    this.setState({ map });
  }

  renderMarkers() {
    for (const point of this.state.points) {
      L.marker(point)
        .bindPopup('To delete this marker, click the Reset button.')
        .addTo(this.state.map);
    }

    if (this.state.points.length === 2) {
      L.rectangle(L.latLngBounds([this.state.points[0], this.state.points[1]]))
        .addTo(this.state.map);
    }
  }

  reset() {
    this.setState({ points: [] });
    this.state.map.eachLayer(layer => {
      if (layer.getAttribution?.() === null) {
        this.state.map.removeLayer(layer);
      }
    });
  }

  submit() {
    fetch('poll', {
      body: JSON.stringify(this.state.points),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then(response => {
        if (!response.ok) {
          response.text().then(text => alert(text));
        } else {
          response.text().then(text => window.location.replace(text));
        }
      });
  }

  render() {
    return (
      <div>
        <p>Select two points to be the corners of a rectangle bounding the poll area.</p>
        <div id="map"></div>
        <div id="buttons">
          <button id="reset" className="btn btn-secondary" onClick={this.reset}>Reset</button>
          <button id="submit" className="btn btn-primary" onClick={this.submit}>Submit</button>
        </div>
      </div>
    );
  }
}
