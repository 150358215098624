import 'bootstrap/dist/css/bootstrap.css';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

if (baseUrl === null) {
  throw Error('baseUrl is null');
}

const rootElement = document.getElementById('root');

if (rootElement === null) {
  throw Error('rootElement is null');
}

const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);
