import React from 'react';

export default function Privacy() {
  return (
    <div>
      <h1>Privacy</h1>
      <p>
        When a poll or vote is created, the IP address of the user performing
        the action is saved in the PollWhere database. This IP address is
        used for the following purposes:
      </p>
      <ul>
        <li>Prevent one user from voting multiple times on a given poll</li>
        <li>Allow a user to edit a previously cast vote</li>
        <li>Allow use of the website without requiring account registration</li>
      </ul>
      <p>
        It is not possible for a user to retrieve any IP addresses from the
        PollWhere database.
      </p>
    </div>
  );
}
