import React from "react";
import About from "./components/About";
import CreatePoll from "./components/CreatePoll";
import Home from "./components/Home";
import Privacy from "./components/Privacy";
import ViewPoll from "./components/ViewPoll";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/create-poll',
    element: <CreatePoll />
  },
  {
    path: '/view-poll/:pollId',
    element: <ViewPoll />
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
    path: '/about',
    element: <About />
  },
];

export default AppRoutes;
